/** @format */

import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Main from '../../components/Main';

import classes from './afterRegenerate.module.scss';
import LinkButton from "../../components/Button/LinkButton";
import routes from "../../utils/routes";


interface AfterRegeneratePageProps {

}

const AfterRegeneratePage: FC<AfterRegeneratePageProps> = props => {
	const {} = props;
	
	const {t} = useTranslation();
	
	return (
		<Main className={classes.main_message}>
			<div className={classes.message}>
				<h1 className={classes.message_title}>
					{t('creation.success_step.title')}
				</h1>
				<p className={classes.message_description}>
					{t('creation.success_step.description')}
				</p>
				<div className={classes.message_actions}>
					<LinkButton to={routes.homePage()}>
						{t('common.go_to_homepage')}
					</LinkButton>
				</div>
			</div>
		</Main>
	)
}

export default AfterRegeneratePage;