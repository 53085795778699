import React, {FC, useEffect, useRef, useState} from "react";

import classes from './stepperAnimator.module.scss';

interface StepperAnimatorProps {
    direction: 'forward' | 'backward';
    children: React.ReactElement | null;
}

const StepperAnimator:FC<StepperAnimatorProps> = props => {
    const {
        children,
        direction,
    } = props;

    const [containerTop, setContainerTop] = React.useState<number>(0);
    const [containerElement, setContainerElement] = React.useState<HTMLDivElement | null>(null);

    const [isAnimationRunning, setIsAnimationRunning] = useState<boolean>(false);
    const [animationDirection, setAnimationDirection] = useState<string>(direction);

    const [currentFrame, setCurrentFrame] = useState<React.ReactElement | null>(children);
    const [currentFrameElement, setCurrentFrameElement] = useState<HTMLDivElement | null>(null);
    const [currentFrameWidth, setCurrentFrameWidth] = useState(0);
    const [currentFrameHeight, setCurrentFrameHeight] = useState(0);

    const [nextFrame, setNextFrame] = useState<React.ReactElement | null>(null);
    const [nextFrameElement, setNextFrameElement] = useState<HTMLDivElement | null>(null);
    const [nextFrameWidth, setNextFrameWidth] = useState(0);
    const [nextFrameHeight, setNextFrameHeight] = useState(0);

    const queuedFrameRef = useRef<React.ReactElement | null>(null);
    
    useEffect(() => {
        if (isAnimationRunning) {
            const previousBodyOverflowX = document.body.style.overflowX;
            document.body.style.overflowX = 'hidden';
            
            return () => {
                document.body.style.overflowX = previousBodyOverflowX;
            }
        }
    }, [isAnimationRunning]);
    
    // useEffect(() => {
    //     if (!isAnimationRunning && queuedFrameRef.current !== null) {
    //         setNextFrame(queuedFrameRef.current);
    //         queuedFrameRef.current = null;
    //     }
    // }, [isAnimationRunning]);

    useEffect(() => {
        if (currentFrame === null) {
            setCurrentFrame(children);
        } else if (children?.key !== currentFrame.key) {
            if (isAnimationRunning) {
                queuedFrameRef.current = children;
            } else {
                setNextFrame(children);
            }
        } else {
            if (isAnimationRunning && nextFrame !== null) {
                if (children.key === currentFrame.key) {
                    setNextFrame(children);
                } else {
                    queuedFrameRef.current = children;
                }
            } else {
                setCurrentFrame(children);
            }
        }
    }, [children]);

    useEffect(() => {
        if (
            currentFrameElement !== null &&
            nextFrameElement !== null
        ) {
            if (containerElement !== null) {
                setContainerTop(containerElement.offsetTop - window.scrollY);
            }
            
            setCurrentFrameHeight(currentFrameElement.offsetHeight);
            setCurrentFrameWidth(currentFrameElement.offsetWidth);

            setNextFrameHeight(nextFrameElement.offsetHeight);
            setNextFrameWidth(nextFrameElement.offsetWidth);

            setAnimationDirection(direction);
            setIsAnimationRunning(true);
        }
    }, [nextFrameElement]);
    
    useEffect(() => {
        if (isAnimationRunning && containerElement !== null) {
            let raf: number | null = null;
            
            const handler = () => {
                setContainerTop(containerElement.offsetTop - window.scrollY);
                raf = requestAnimationFrame(handler);
            }
            
            raf = requestAnimationFrame(handler);
            
            return () => {
                if (raf !== null) {
                    cancelAnimationFrame(raf);
                }
            };
        }
    }, [isAnimationRunning]);

    const handleAnimationEnd = (event: React.AnimationEvent) => {
        if (event.target === event.currentTarget) {
            setIsAnimationRunning(false);
            setCurrentFrame(nextFrame);
            setNextFrame(null);
            setCurrentFrameWidth(nextFrameWidth);
            setCurrentFrameHeight(nextFrameHeight);
        }
    }

    const currentFrameNode = currentFrame && (
        <div
            data-animation-direction={animationDirection}
            className={classes.current_animation_frame}
            key={currentFrame.key}
            ref={setCurrentFrameElement}
            data-is-animation-running={isAnimationRunning}
        >
            {currentFrame}
        </div>
    )

    const nextFrameNode = nextFrame && (
        <div
            data-animation-direction={animationDirection}
            className={classes.next_animation_frame}
            key={nextFrame.key}
            ref={setNextFrameElement}
            data-is-animation-running={isAnimationRunning}
        >
            {nextFrame}
        </div>
    )
    
    return (
        <div
            style={{
                '--current-width': currentFrameWidth+'px',
                '--current-height': currentFrameHeight+'px',
                '--next-width': nextFrameWidth+'px',
                '--next-height': nextFrameHeight+'px',
                '--top': containerTop+'px',
            } as React.CSSProperties}
            onAnimationEnd={handleAnimationEnd}
            className={classes.animation_container}
            data-is-animation-running={isAnimationRunning}
            ref={setContainerElement}
        >
            {currentFrameNode}
            {nextFrameNode}
        </div>
    )
}

export default StepperAnimator;