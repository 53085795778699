/** @format */

export enum EUserType {
	teacher = 'Teacher',
	parent = 'Parent',
	lsa = 'LSA'
}

export enum ESourceType {
	classroomBook = 'ClassroomBook',
	classroomBooklet = 'ClassroomBooklet',
	websiteText = 'WebsiteText',
}

export enum ELessonActivity {
	learningNewVocabulary = 'LearningNewVocabulary',
	pictures = 'Pictures',
	sequence = 'Sequence',
	yesNoQuestions = 'YesNoQuestions',
	fillInBlank = 'FillInBlank',
	shortVideo = 'ShortVideo',
}