/** @format */

import React, {ChangeEvent, FC, useCallback, useRef, useState} from 'react';

import classes from './checkboxBlock.module.scss'
import {clsx} from "../../utils/commonFunctions";

interface CheckboxBlockProps {
	checked?: boolean,
	value?: string,
	onSet?: (checked: boolean) => void,
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void,
	children?: React.ReactNode,
	radio?: boolean,
	name?: string,
	className?: string,
	disabled?: boolean,
}

const CheckboxBlock: FC<CheckboxBlockProps> = props => {
	const {
		checked,
		onSet,
		children,
		radio,
		name,
		value,
		onChange,
		className,
		disabled = false,
	} = props;
	
	const checkboxRef = useRef<HTMLInputElement>(null);
	
	const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		onSet?.(event.currentTarget.checked);
		onChange?.(event);
	}, [onSet, onChange]);
	
	const handleLabelKeyDown = useCallback((event: React.KeyboardEvent<HTMLLabelElement>) => {
		if (!disabled) {
			if (event.key === 'Enter' || event.code === 'Space') {
				const checkboxElem = checkboxRef.current;
				
				if (checkboxElem !== null) {
					checkboxElem.click();
				}
			}
		}
	}, [checkboxRef, disabled]);
	
	return (
		<label
			className={clsx(classes.checkbox_block, className)}
			tabIndex={0}
			onKeyDown={handleLabelKeyDown}
			aria-disabled={disabled}
		>
			<input
				type={radio ? 'radio' : 'checkbox'}
				tabIndex={-1}
				checked={checked}
				onChange={handleChange}
				className={classes.target_input}
				ref={checkboxRef}
				name={name}
				value={value}
				disabled={disabled}
			/>
			<div className={classes.fake_checkbox}/>
			{children}
		</label>
	)
}

export default CheckboxBlock;