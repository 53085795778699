/** @format */

import React, {FC, useCallback} from 'react';

import Stepper from '../../../components/Stepper';
import Button from '../../../components/Button';
import FileField from '../../../components/FileField';

import {TCreateStoryPageStepProps} from "../createStoryPageTypes";

import classes from './scanPageStepForm.module.scss'
import Select from "../../../components/Select/Select";
import {languageOptions} from "../../../utils/commonConstants";
import {useTranslation} from "react-i18next";

interface UploadDocumentFormProps extends TCreateStoryPageStepProps {
	file: File | null,
	setFile: (file: File | null) => void,
	
	language: string,
	setLanguage: (language: string) => void,
}

const UploadDocumentForm: FC<UploadDocumentFormProps> = props => {
	const {
		file,
		setFile,
		
		onBack,
		onSubmit,
		
		language,
		setLanguage,
	} = props;
	
	const {t} = useTranslation();
	
	const handleSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		onSubmit();
	}, [onSubmit]);
	
	return (
		<form action="#" className={classes.upload_document_form} onSubmit={handleSubmit}>
			<h1 className={classes.title}>{t('creation.upload_document_step.title')}</h1>
			<h3 className={classes.description}>{t('creation.upload_document_step.description')}</h3>
			<div className={classes.center}>
				<div className={classes.select_container}>
					<h5 className={classes.select_label}>
						{t('creation.upload_document_step.select_language')}
					</h5>
					<Select
						options={languageOptions}
						value={language}
						onSet={setLanguage}
					/>
				</div>
			</div>
			<div className={classes.center}>
				<FileField
					required
					file={file}
					onSet={setFile}
					accept={allowedMimeTypes}
				/>
			</div>
			<div className={classes.actions}>
				<Button variant="outline" onClick={onBack}>{t('common.back')}</Button>
				<Button type="submit">{t('common.next')}</Button>
			</div>
		</form>
	)
}

const allowedMimeTypes = [
	'application/pdf',
	'application/msword',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
	'application/vnd.ms-word.document.macroEnabled.12',
	'application/vnd.ms-word.template.macroEnabled.12',
	'image/*',
]

export default UploadDocumentForm;