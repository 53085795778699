/** @format */

import {useMutation, useQuery} from 'react-query';
import queryString from 'query-string';

import {downloadFromResponse} from '../../utils/commonFunctions';

import {apiV1Url} from '../../utils/commonConstants';

// Get status
export const checkStatusQueryKey = 'pdf_status'

const fetchPdfStatus = async ({queryKey: [,sessionId]}) => {
	const response = await fetch(`${apiV1Url}/mutation/current/pdf/status?${queryString.stringify({token: sessionId})}`, {
		headers: {
			'Authorization': `Bearer ${sessionId}`,
		}
	});
	
	if (response.ok) {
		return await response.json();
	} else {
		throw await response.json();
	}
}

interface TPdfStatus {
	status: 'finished' | 'started'
}

export const usePdfStatus = (sessionId: string) => {
	const query = useQuery<TPdfStatus, unknown, TPdfStatus, [string, string]>({
		queryKey: [checkStatusQueryKey, sessionId],
		queryFn: fetchPdfStatus,
		keepPreviousData: true,
		staleTime: 1000 * 30,
		refetchInterval: data => {
			if (data === undefined) {
				return 1000 * 60;
			}

			return data.status !== 'finished' ? 1000 * 60 : false;
		},
		refetchOnWindowFocus: true,
	});
	
	return {
		pdfStatus: query.data
	}
}

// Download pdf

interface TDownloadPdfParams {
	sessionId: string
}

const downloadPdfRequest = async ({sessionId}:TDownloadPdfParams) => {
	const response = await fetch(`${apiV1Url}/mutation/current/pdf/download?${queryString.stringify({token: sessionId})}`, {
		headers: {
			'Authorization': `Bearer ${sessionId}`,
		}
	});
	
	if (response.ok) {
		return await response;
	} else {
		throw await response.json();
	}
}

export const useDownloadPdf = () => {
	const mutation = useMutation<Response, unknown, TDownloadPdfParams>({
		mutationFn: downloadPdfRequest,
		onSuccess: async (response) => {
			await downloadFromResponse(response, 'download.pdf');
		}
	});
	
	return {
		downloadPdf: mutation.mutate,
		isPdfDownloading: mutation.isLoading,
	}
}