/** @format */

import React, {FC, useCallback} from 'react';
import classes from "./editingBlock.module.scss";

interface TextAreaProps {
	value: string;
	onSet: (value: string) => void;
	placeholder?: string;
	autoFocus?: boolean;
}

const TextArea: FC<TextAreaProps> = props => {
	const {value, onSet, placeholder, autoFocus = false} = props;
	
	const [height, setHeight] = React.useState(120);
	
	const handleChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
		onSet(event.currentTarget.value);
	}, [onSet]);
	
	const textAreaAutosize = useCallback((event: React.FormEvent<HTMLTextAreaElement>) => {
		event.currentTarget.style.height = 'auto';
		const height = event.currentTarget.scrollHeight - 4;
		event.currentTarget.style.height = height + 'px';
		setHeight(height);
	}, [setHeight]);
	
	return (
		<textarea
			style={{height}}
			onInput={textAreaAutosize}
			className={classes.textarea}
			value={value}
			onChange={handleChange}
			placeholder={placeholder}
			autoFocus={autoFocus}
		/>
	)
}

export default TextArea;