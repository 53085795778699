/** @format */

import React, {ChangeEvent, FC, useCallback} from 'react';

import Stepper from "../../../components/Stepper";
import CheckboxBlock from "../../../components/CheckboxBlock";
import Button from "../../../components/Button";

import {TCreateStoryPageStepProps} from "../createStoryPageTypes";
import {EUserType} from "../createStoryTypes";

import classes from './areYouStepForm.module.scss';

import teacherImg from "./teacher_img.svg";
import parentsImg from "./parents_img.svg";
import teacherStudentImg from "./teacher_student_img.svg";
import {useTranslation} from "react-i18next";

interface AreYouStepFormProps extends TCreateStoryPageStepProps {
	userType: string,
	setUserType: (userType: EUserType) => void,
}

const AreYouStepForm: FC<AreYouStepFormProps> = props => {
	const {
		userType,
		setUserType,

		onBack,
		onSubmit,
	} = props;
	
	const {t} = useTranslation();
	
	const handleSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		onSubmit();
	}, [onSubmit]);
	
	const handleCustomerTypeChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		if (event.currentTarget.checked) {
			setUserType(event.currentTarget.value as EUserType);
		}
	}, [setUserType]);
	
	return (
		<form
			action="#"
			className={classes.are_you_form}
			onSubmit={handleSubmit}
		>
			<h1 className={classes.title}>{t('creation.are_you_step.title')}</h1>
			<div className={classes.checkbox_blocks}>
				<CheckboxBlock
					checked={userType === EUserType.teacher}
					radio
					name="user_type"
					value={EUserType.teacher}
					onChange={handleCustomerTypeChange}
				>
					<div className={classes.checkbox_block_image_with_text}>
						<img
							src={teacherImg}
							alt="Teather"
							width={196}
							height={146}
						/>
						<p>{t('creation.are_you_step.teacher')}</p>
					</div>
				</CheckboxBlock>
				<CheckboxBlock
					checked={userType === EUserType.parent}
					radio
					name="user_type"
					value={EUserType.parent}
					onChange={handleCustomerTypeChange}
				>
					<div className={classes.checkbox_block_image_with_text}>
						<img
							src={parentsImg}
							alt="Parents"
							width={196}
							height={146}
						/>
						<p>{t('creation.are_you_step.parent')}</p>
					</div>
				</CheckboxBlock>
				<CheckboxBlock
					checked={userType === EUserType.lsa}
					radio
					name="user_type"
					value={EUserType.lsa}
					onChange={handleCustomerTypeChange}
				>
					<div className={classes.checkbox_block_image_with_text}>
						<img
							src={teacherStudentImg}
							alt="Teacher student"
							width={139}
							height={146}
						/>
						<p>{t('creation.are_you_step.lsa')}</p>
					</div>
				</CheckboxBlock>
			</div>
			<div className={classes.actions}>
				<Button variant="outline" onClick={onBack}>{t('common.back')}</Button>
				<Button type="submit">{t('common.next')}</Button>
			</div>
		</form>
	)
}

export default AreYouStepForm;