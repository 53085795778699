/** @format */

import React, {FC, memo, useCallback} from 'react';

import LearningMaterialsImages from './LearningMaterialsImages';
import LearningMaterialsParagraphs from './LearningMaterialsParagraphs';
import TextEditingBlock from './TextEditingBlock';

import {TLearningMaterials} from '../learningMaterialsTypes';
import LearningMaterialsWords from "./LearningMaterialsWords";
import ParagraphText from "./ParagraphText";

interface LearningMaterialsProps {
	title?: string;
	subTitle?: string;
	
	editable: boolean;
	
	materials: TLearningMaterials,
	setMaterials: React.Dispatch<React.SetStateAction<TLearningMaterials>>
}

const createChanger = <K extends keyof TLearningMaterials>(mainSetter: React.Dispatch<React.SetStateAction<TLearningMaterials>>, fieldName: K):React.Dispatch<React.SetStateAction<TLearningMaterials[K]>> => action => {
	mainSetter(materials => ({
		...materials,
		[fieldName]: typeof action === 'function' ? action(materials[fieldName]) : action,
	}));
}

const LearningMaterials: FC<LearningMaterialsProps> = props => {
	const {
		materials,
		setMaterials,
		title,
		subTitle,
		editable,
	} = props;
	
	const handleSetImages = useCallback(createChanger(setMaterials, 'images'), [setMaterials]);
	const handleSetParagraphs = useCallback(createChanger(setMaterials, 'paragraphs'), [setMaterials]);
	const handleSetText = useCallback(createChanger(setMaterials, 'text'), [setMaterials]);
	const handleSetWords = useCallback(createChanger(setMaterials, 'words'), [setMaterials]);
	
	let hasTitle = false;
	const getTitle = () => {
		if (hasTitle) {
			return undefined;
		} else {
			hasTitle = true;
			return title;
		}
	}

	let hasSubtitle = false;
	const getSubTitle = () => {
		if (hasSubtitle) {
			return undefined;
		} else {
			hasSubtitle = true;
			return subTitle;
		}
	}
	
	
	return (
		<React.Fragment>
			{'images' in materials && (
				<LearningMaterialsImages
					key="images"
					title={getTitle()}
					subTitle={getSubTitle()}
					images={materials.images}
					setImages={handleSetImages}
				/>
			)}
			{'paragraphs' in materials && (
				<LearningMaterialsParagraphs
					key="paragraphs"
					editable={editable}
					title={getTitle()}
					subTitle={getSubTitle()}
					paragraphs={materials.paragraphs}
					setParagraphs={handleSetParagraphs}
				/>
			)}
			{'text' in materials && (
				<TextEditingBlock
					key="text"
					editable={editable}
					title={getTitle()}
					subTitle={getSubTitle()}
					text={materials.text}
					// @ts-ignore
					setText={handleSetText}
				/>
			)}
			{'words' in materials && (
				<LearningMaterialsWords
					key="words"
					editable={editable}
					title={getTitle()}
					subTitle={getSubTitle()}
					words={materials.words}
					setWords={handleSetWords}
				/>
			)}
		</React.Fragment>
	)
}

export default memo(LearningMaterials);