import React, {FC} from 'react';
import Router from "./Router";


const App: FC = () => {
	return (
		<Router/>
	);
}

export default App;
