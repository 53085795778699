/** @format */

import React, {FC, useState} from 'react';
import CheckboxBlock from "../../components/CheckboxBlock/CheckboxBlock";
import Stepper from "../../components/Stepper";
import Background from "../../components/Background";
import Input from "../../components/Input";
import FileField from "../../components/FileField";
import StepperAnimator from "../../components/StepperAnimator/StepperAnimator";

import classes from "./playGroundPage.module.scss";
import LoadingStep from "../CreateStoryPage/LoadingStep/LoadingStep";
import Checkbox from "../../components/Checkbox/Checkbox";

const PlayGroundPage: FC = () => {

	const [file, setFile] = useState<File | null>(null);

	const [sliderCounter, setSliderCounter] = useState<number>(0);
	
	return (
		<Background>
			<CheckboxBlock>
				<div style={{width: 100, height: 100}}/>
			</CheckboxBlock>
			
			<Stepper max={3} value={2}/>
			
			<Input placeholder="Enter the Lesson Name"/>
			
			<FileField file={file} onSet={setFile}/>

			<StepperAnimator direction="forward">
				<div
					className={classes.block}
					key={sliderCounter}
					style={{
						background: sliderCounter % 2 === 0 ? 'red' : 'green',
					}}
				/>
			</StepperAnimator>
			<button
				onClick={() => setSliderCounter(count => count + 1)}
			>
				Next
			</button>
			<LoadingStep/>
			<Checkbox/>
		</Background>
	)
}

export default PlayGroundPage;