/** @format */

import React, {FC, useCallback} from 'react';

import classes from './input.module.scss';

interface InputProps {
	// label: string,
	// required?: boolean,
	placeholder?: string,
	value?: string,
	onSet?: (value: string) => void,
	type?: React.InputHTMLAttributes<HTMLInputElement>['type'],
	autoComplete?: React.InputHTMLAttributes<HTMLInputElement>['autoComplete'],
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
	id?: string,
	name?: string,
	required?: boolean,
}

const Input: FC<InputProps> = props => {
	const {
		placeholder,
		onSet,
		value,
		onChange,
		type = 'text',
		name,
		autoComplete,
		id,
		required,
	} = props;
	
	const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		onSet?.(event.currentTarget.value);
		onChange?.(event);
	}, [onSet]);
	
	return (
		<input
			required={required}
			className={classes.input}
			type={type}
			placeholder={placeholder}
			value={value}
			onChange={handleChange}
			name={name}
			autoComplete={autoComplete}
			id={id}
		/>
	)
}

export default Input;