/** @format */

import React, {FC, useCallback} from 'react';
import ParagraphText from "./ParagraphText";
import TextEditingBlock from "./TextEditingBlock";
import {TLearningMaterials, TParagraph} from "../learningMaterialsTypes";
import LearningMaterialsImages from "./LearningMaterialsImages";
import LearningMaterialsParagraphs from "./LearningMaterialsParagraphs";
import LearningMaterialsWords from "./LearningMaterialsWords";

interface ParagraphProps {
	title?: string;
	subTitle?: string;
	
	editable: boolean;
	
	paragraph: TParagraph;
	setParagraph: React.Dispatch<React.SetStateAction<TParagraph>>
}

const createChanger = <K extends keyof TParagraph>(mainSetter: React.Dispatch<React.SetStateAction<TParagraph>>, fieldName: K):React.Dispatch<React.SetStateAction<TParagraph[K]>> => action => {
	mainSetter(materials => ({
		...materials,
		[fieldName]: typeof action === 'function' ? action(materials[fieldName]) : action,
	}));
}

const Paragraph: FC<ParagraphProps> = props => {
	const {paragraph, setParagraph, editable, title, subTitle} = props;
	
	const handleSetImages = useCallback(createChanger(setParagraph, 'images'), [setParagraph]);
	const handleSetParagraphs = useCallback(createChanger(setParagraph, 'paragraphs'), [setParagraph]);
	const handleSetText = useCallback(createChanger(setParagraph, 'text'), [setParagraph]);
	const handleSetWords = useCallback(createChanger(setParagraph, 'words'), [setParagraph]);
	
	let hasTitle = false;
	const getTitle = () => {
		if (hasTitle) {
			return undefined;
		} else {
			hasTitle = true;
			return title;
		}
	}
	
	let hasSubtitle = false;
	const getSubTitle = () => {
		if (hasSubtitle) {
			return undefined;
		} else {
			hasSubtitle = true;
			return subTitle;
		}
	}
	
	return (
		<React.Fragment>
			
			{'text' in paragraph && (
				<ParagraphText
					key="text"
					editable={editable}
					title={getTitle()}
					subTitle={getSubTitle()}
					text={paragraph.text}
					setText={handleSetText}
				/>
			)}
			
			{'words' in paragraph && (
				<LearningMaterialsWords
					key="words"
					editable={editable}
					title={getTitle()}
					subTitle={getSubTitle()}
					words={paragraph.words}
					setWords={handleSetWords}
				/>
			)}
			
			{'images' in paragraph && (
				<LearningMaterialsImages
					key="images"
					title={getTitle()}
					subTitle={getSubTitle()}
					images={paragraph.images}
					setImages={handleSetImages}
				/>
			)}
			
			{'paragraphs' in paragraph && (
				<LearningMaterialsParagraphs
					key="paragraphs"
					editable={editable}
					title={getTitle()}
					subTitle={getSubTitle()}
					paragraphs={paragraph.paragraphs}
					setParagraphs={handleSetParagraphs}
				/>
			)}
		</React.Fragment>
	)
}

export default Paragraph;