/** @format */

import React, {FC} from 'react';

import classes from './loadingStep.module.scss';

interface LoadingStepProps {

}

const LoadingStep: FC<LoadingStepProps> = props => {
	const {} = props;
	
	return (
		<div className={classes.form}>
			<h1 className={classes.center}>
				<div className={classes.loader}/>
			</h1>
		</div>
	)
}

export default LoadingStep;