/** @format */

import React, {FC} from 'react';
import {clsx} from "../../utils/commonFunctions";

import classes from './main.module.scss'
import {useTranslation} from "react-i18next";

interface MainProps {
	children?: React.ReactNode,
	className?: string,
}

const Main: FC<MainProps> = props => {
	const {children, className} = props;
	
	const {i18n} = useTranslation();
	
	return (
		<main
			dir={i18n.dir()}
			className={clsx(classes.main, className)}
		>
			{children}
		</main>
	)
}

export default Main;