/** @format */

import React, {FC, useCallback} from 'react';

import classes from './checkbox.module.scss'

interface CheckboxProps {
    checked?: boolean;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onSet?: (checked: boolean) => void;
}

const Checkbox: FC<CheckboxProps> = props => {
	const {checked, onChange, onSet} = props;
	
	const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		onChange?.(event);
		onSet?.(event.currentTarget.checked);
	}, [onChange, onSet]);
	
	return (
		<input
			type="checkbox"
			className={classes.checkbox}
			onChange={handleChange}
			checked={checked}
		/>
	)
}

export default Checkbox;