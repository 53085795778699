import React, {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router';

import Button from '../../components/Button';
import Main from '../../components/Main';
import Header from '../../components/Header';
import Loader from '../../components/Loader/Loader';

import LearningMaterials from './EditingBlock/LearningMaterials';

import {useDownloadPdf, useFetchLessonStructure, useMutateStructure} from './lessonEditorQueries';

import {
	TImageMaterialStructure,
	TLearningMaterials,
	TLearningMaterialsStructure, TParagraph,
	TTextMaterialStructure, TWordMaterialStructure
} from './learningMaterialsTypes';

import classes from './learningMaterialsEditorPage.module.scss';
import routes from "../../utils/routes";
import {useTranslation} from "react-i18next";

// import printIconImg from './printIcon.svg';


interface LearningMaterialsEditorPageProps {

}

const LearningMaterialsEditorPage: FC<LearningMaterialsEditorPageProps> = () => {

	const navigate = useNavigate();
	const {t} = useTranslation();
	
	const sessionId = useParams<{ sessionId: string }>().sessionId as string;
	
	const [topic, setTopic] = useState<string>('');
	const [learningMaterialsStructure, setLearningMaterialsStructure] = useState<TLearningMaterialsStructure>({});
	
	const handleChange = useCallback((key: string, action: React.SetStateAction<TLearningMaterials>) => {
		setLearningMaterialsStructure(materials => ({
			...materials,
			[key]: typeof action === 'function' ? action(materials[key]) : action,
		}));
	}, [setLearningMaterialsStructure])
	
	const {fetchLessonStructure, isLessonStructureLoading} = useFetchLessonStructure();
	const {mutateStructure, isMutateStructureLoading} = useMutateStructure();
	const {downloadPdf, isPdfDownloading} = useDownloadPdf();
	
	const handleDownload = useCallback(() => {
		downloadPdf({token: sessionId}, {
			onSuccess: () => {
				navigate(routes.homePage())
			}
		})
	}, [downloadPdf, sessionId]);
	
	useEffect(() => {
		fetchLessonStructure({
			token: sessionId,
		}, {
			onSuccess: (data) => {
				setLearningMaterialsStructure(data.structure);
				setTopic(data.parameters.topic);
			}
		});
	}, [sessionId]);
	
	const handleRegenerate = useCallback(() => {
		mutateStructure({
			token: sessionId,
			structure: learningMaterialsStructure,
		}, {
			onSuccess: () => {
				navigate(routes.afterRegenerate())
			}
		})
	}, [sessionId, mutateStructure, learningMaterialsStructure]);
	
	const hasEditing = useMemo(() => {
		return hasEditingInLearningMaterialsStructure(learningMaterialsStructure);
	}, [learningMaterialsStructure]);

	const keys = Object.keys(learningMaterialsStructure);
	keys.sort((a, b) => {
		if (a === 'main_page') {
			return -1;
		} else if (b === 'main_page') {
			return 1;
		} else {
			return 0;
		}
	});
	
	if (isLessonStructureLoading) {
		return (
			<Main className={classes.main_loader}>
				<Loader/>
			</Main>
		)
	}
	
	if (isPdfDownloading) {
		return (
			<Main className={classes.main_message}>
				<div className={classes.message}>
					<h1 className={classes.message_title}>
						{t('pending_page.generating_title')}
					</h1>
					<p className={classes.message_description}>
						{t('pending_page.generating_description')}
					</p>
				</div>
			</Main>
		)
	}
	
	return (
		<Main className={classes.main}>
			<Header/>
			<div className={classes.learning_materials_editor_page}>
				<div className={classes.header}>
					<h1 className={classes.title}>{topic}</h1>
					{/*<Button variant="text">*/}
					{/*	<img src={printIconImg} alt="print"/>*/}
					{/*	Print*/}
					{/*</Button>*/}
				</div>
				
				<div className={classes.content}>
					{keys.map(key => (
						<LearningMaterials
							key={key}
							editable={key === 'main_page'}
							title={titles[key]}
							subTitle={descriptions[key]}
							materials={learningMaterialsStructure[key]}
							setMaterials={materials => handleChange(key, materials)}
						/>
					))}
				</div>
				
				<div className={classes.actions}>
					<Button
						variant="outline"
						disabled={!hasEditing || isMutateStructureLoading || isPdfDownloading}
						onClick={handleRegenerate}
					>
						{isMutateStructureLoading && (
							<Loader size={16} width={1}/>
						)}
						{t('common.regenerate')}
					</Button>
					<Button
						disabled={hasEditing || isMutateStructureLoading || isPdfDownloading}
						onClick={handleDownload}
					>
						{t('learning_materials_editor.save_lesson_as_pdf')}
					</Button>
				</div>
			</div>
		</Main>
	)
}

const hasEditingInMaterial = (material: TTextMaterialStructure | TImageMaterialStructure | TWordMaterialStructure) => {
	return material.update || material.regenerate;
}

const hasEditingInParagraph = (paragraph: TParagraph) => {
	
	if ('images' in paragraph) {
		for (const image of Object.values(paragraph.images)) {
			if (hasEditingInMaterial(image)) {
				return true;
			}
		}
	}
	
	if ('text' in paragraph) {
		for (const text of Object.values(paragraph.text)) {
			if (hasEditingInMaterial(text)) {
				return true;
			}
		}
	}
	
	if ('words' in paragraph) {
		for (const word of Object.values(paragraph.words)) {
			if (hasEditingInMaterial(word)) {
				return true;
			}
		}
	}
	
	return false;
}

const hasEditingInLearningMaterials = (learningMaterials: TLearningMaterials) => {
	
	if ('images' in learningMaterials) {
		for (const image of Object.values(learningMaterials.images)) {
			if (hasEditingInMaterial(image)) {
				return true;
			}
		}
	}
	
	if ('text' in learningMaterials) {
		if (hasEditingInMaterial(learningMaterials.text)) {
			return true;
		}
	}
	
	if ('paragraphs' in learningMaterials) {
		for (const paragraphs of Object.values(learningMaterials.paragraphs)) {
			if (hasEditingInParagraph(paragraphs)) {
				return true;
			}
		}
	}

	if ('words' in learningMaterials) {
		for (const word of Object.values(learningMaterials.words)) {
			if (hasEditingInMaterial(word)) {
				return true;
			}
		}
	}
	
	return false;
}

const hasEditingInLearningMaterialsStructure = (learningMaterials: TLearningMaterialsStructure) => {
	for (const item of Object.values(learningMaterials)) {
		if (hasEditingInLearningMaterials(item)) {
			return true;
		}
	}
	
	return false;
}

const titles = {
	coloring_game: 'Coloring Page',
	short_answer: 'Short Answers',
	fill_in_game: 'Fill-In-The-Blank Activity',
	true_false: 'True/False Questions',
	labeling_game: 'Labeling Page',
	matching_game: 'Matching Page',
	multiple_choice: 'Multiple Choices',
	sequence_game: 'Sequence Game',
	table_completion: 'Table Completion',
}

const descriptions = {
	coloring_game: 'Color the image with different colors. Use your imagination to make the scene vibrant and exciting.',
	short_answer: 'Answer the following questions in one or two words:',
	true_false: 'Mark each statement as True or False based on what you learned.',
}

export default LearningMaterialsEditorPage;