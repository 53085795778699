/** @format */

import React, {FC} from 'react';

import LinkButton from '../../../components/Button/LinkButton';
import Stepper from '../../../components/Stepper';

import routes from '../../../utils/routes';

import classes from './successStep.module.scss';
import {useTranslation} from "react-i18next";

interface SuccessStepProps {
	step: number,
	totalSteps: number,
	setStep: (step: number) => void,
}

const SuccessStep: FC<SuccessStepProps> = props => {
	const {
		step,
		totalSteps,
		setStep,
	} = props;
	
	const {t} = useTranslation();
	
	return (
		<div className={classes.success_step}>
			<div className={classes.actions}>
				<Stepper
					value={step}
					max={totalSteps}
					onSet={setStep}
				/>
			</div>
			<h1 className={classes.title}>
				{t('creation.success_step.title')}
			</h1>
			<h3 className={classes.description}>
				{t('creation.success_step.description')}
			</h3>
			<div className={classes.actions}>
				<LinkButton to={routes.homePage()}>
					{t('common.go_to_homepage')}
				</LinkButton>
			</div>
		</div>
	)
}

export default SuccessStep;