/** @format */

import React, {FC} from 'react';

import classes from './button.module.scss'

interface ButtonProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'className' | 'children'>{
	children?: React.ReactNode,
	variant?: 'primary' | 'outline' | 'text',
}

const Button: FC<ButtonProps> = props => {
	const {children, type = "button", variant = 'primary', ...nativeProps} = props;
	
	return (
		<button
			{...nativeProps}
			className={classes.button}
			type={type}
			data-variant={variant}
		>
			{children}
		</button>
	)
}

export default Button;