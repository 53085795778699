/** @format */

import React, {FC, useCallback} from 'react';

import Input from "../../../components/Input";
import Button from "../../../components/Button";

import classes from "./zeroStepForm.module.scss";
import {useTranslation} from "react-i18next";

interface ZeroStepFormProps {
	userName: string,
	setUserName: (userName: string) => void,
	
	topic: string,
	setTopic: (lessonName: string) => void,
	
	email: string,
	setEmail: (email: string) => void,
	
	onSubmit: () => void,
}

const ZeroStepForm: FC<ZeroStepFormProps> = props => {
	const {
		userName,
		setUserName,
		
		topic,
		setTopic,
		
		email,
		setEmail,
		
		onSubmit,
	} = props;
	
	const {t} = useTranslation();
	
	const handleSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		onSubmit();
	}, [onSubmit]);
	
	return (
		<form
			action="#"
			className={classes.zero_step_form}
			name="setup_user"
			onSubmit={handleSubmit}
		>
			<h3 className={classes.description}>
				{t('creation.zero_step.title')}
			</h3>
			<div className={classes.fields}>
				<Input
					value={userName}
					onSet={setUserName}
					placeholder={t('creation.zero_step.enter_your_name')}
					name="user_name"
					autoComplete="name"
					id="user_name"
				/>
				<Input
					value={topic}
					onSet={setTopic}
					placeholder={t('creation.zero_step.enter_lesson_name')}
					name="lesson_name"
					id="lesson_name"
				/>
				<Input
					required
					value={email}
					onSet={setEmail}
					placeholder={t('creation.zero_step.enter_your_email')}
					type="email"
					name="email"
					autoComplete="email"
					id="email"
				/>
			</div>
			<div className={classes.actions}>
				<Button type="submit">{t('common.next')}</Button>
			</div>
		</form>
	)
}

export default ZeroStepForm;