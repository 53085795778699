/** @format */

import React, {FC, useCallback, useEffect, useState} from 'react';

import {TInputProps} from '../../utils/commonTypes';

import classes from './fileField.module.scss'

import documentIconImg from './documentIcon.svg';
import closeIconImg from './closeIcon.svg';

interface FileFieldProps extends Omit<TInputProps, 'className' | 'type' | 'onChange' | 'ref' | 'accept'> {
	file?: File | null,
	onSet?: (file: File | null) => void,
	accept?: string[],
}

const FileField: FC<FileFieldProps> = props => {
	const {
		file,
		onSet,
		accept = [],
		...inputProps
	} = props;
	
	const [inputEl, inputRef] = useState<HTMLInputElement | null>(null);
	
	const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		onSet?.(event.currentTarget.files?.[0] ?? null);
	}, [onSet]);
	
	useEffect(() => {
		if (inputEl !== null) {
			const dataTransfer = new DataTransfer();
			if (file instanceof File) {
				dataTransfer.items.add(file);
			}
			inputEl.files = dataTransfer.files;
		}
	}, [inputEl, file]);
	
	const hasFile = file instanceof File;
	
	const handleClear = useCallback(() => {
		if (inputEl !== null) {
			const dataTransfer = new DataTransfer();
			inputEl.files = dataTransfer.files;
		}
		onSet?.(null);
	}, [onSet, inputEl]);
	
	if (hasFile) {
		return (
			<div className={classes.filled_file_field}>
				<img
					src={documentIconImg}
					width={20}
					height={20}
					alt="document"
				/>
				<p className={classes.label}>
					{file.name}
				</p>
				<button
					type="button"
					className={classes.close_button}
					onClick={handleClear}
				>
					<img
						src={closeIconImg}
						width={16}
						height={16}
						alt="close"
					/>
				</button>
			</div>
		)
	} else {
		return (
			<label
				className={classes.file_field}
				data-has-file={hasFile}
			>
				<input
					ref={inputRef}
					onChange={handleChange}
					type="file"
					className={classes.input}
					accept={accept.join(', ')}
					{...inputProps}
				/>
			</label>
		)
	}
}

export default FileField;