/** @format */

import React, {FC} from 'react';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import {AnimatePresence} from 'framer-motion'

import HomePage from './pages/HomePage';
import PlayGroundPage from './pages/PlayGroundPage';
import CreateStoryPage from './pages/CreateStoryPage';
import PendingResultPage from './pages/PendingResultPage/PendingResultPage';
import LearningMaterialsEditorPage from './pages/LearningMaterialsEditorPage/LearningMaterialsEditorPage';
import AfterRegeneratePage from './pages/AfterRegeneratePage/AfterRegeneratePage';

import routes from './utils/routes';


const Router: FC = () => {
	const location = useLocation();
	
	return (
		<AnimatePresence mode="wait">
			<Routes location={location} key={location.pathname} >
				<Route path={routes.homePage()} element={<HomePage/>}/>
				<Route path={routes.playground()} element={<PlayGroundPage/>}/>
				<Route path={routes.createStoryFlow()} element={<CreateStoryPage/>}/>
				<Route path={routes.pendingResult()} element={<PendingResultPage/>}/>
				<Route path={routes.learningMaterialsEditor()} element={<LearningMaterialsEditorPage/>}/>
				<Route path={routes.afterRegenerate()} element={<AfterRegeneratePage/>}/>
				<Route path="*" element={<Navigate to={routes.homePage()}/>}/>
			</Routes>
		</AnimatePresence>
	)
}

export default Router;