/** @format */

import React, { useCallback, useMemo} from 'react';
import ReactSelect from 'react-select'
import {StylesConfig} from "react-select/dist/declarations/src/styles";

import {neutral_900, primary_50, primary_600} from "../../styleConstants/colors";

interface SelectProps<Value> {
	options: { value: Value, label: string }[],
	value: Value,
	onSet: (value: Value) => void,
}

const Select = <Value extends string>(props: SelectProps<Value>):React.ReactElement => {
	const {options, value, onSet} = props;
	
	const selectValue = useMemo(() => {
		return options.find(option => option.value === value) ?? null;
	}, [options, value])
	
	const handleChange = useCallback((option: any) => {
		onSet(option.value);
	}, [onSet]);
	
	return (
		<ReactSelect
			options={options}
			value={selectValue}
			styles={styles}
			onChange={handleChange}
		/>
	)
}

const fontLabelLarge: React.CSSProperties = {
	fontFamily: 'Lato, sans-serif',
	fontSize: 14,
	fontStyle: 'normal',
	fontWeight: 700,
	letterSpacing: 0.1,
}

const styles: StylesConfig<{ label: string, value: string }, false> = {
	control: base => ({
		...base,
		background: "none",
		border: `1px solid ${primary_600}`,
		borderRadius: 300,
		cursor: "pointer",
		':hover': {
			border: `1px solid ${primary_600}`,
		}
	}),
	singleValue: base => ({
		...base,
		...fontLabelLarge,
		color: primary_50,
		// color: neutral_900,
	}),
	indicatorSeparator: base => ({
		display: 'none',
	}),
	dropdownIndicator: base => ({
		...base,
		'& svg': {
			fill: primary_50,
			// fill: neutral_900,
		},
	}),
	option: (base, props) => {
		
		if (props.isSelected) {
			return {
				...base,
				background: primary_600,
				color: primary_50,
				...fontLabelLarge,
			}
		} else {
			return {
				...base,
				background: primary_50,
				color: primary_600,
				...fontLabelLarge,
			}
		}
	},
	menu: base => ({
		...base,
		background: primary_50,
	})
}

export default Select;