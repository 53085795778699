/** @format */

import React, {FC, useCallback} from 'react';
import LearningMaterials from "./LearningMaterials";
import {
    TLearningMaterials,
    TParagraph,
    TTextMaterialStructure,
    TWordMaterialStructure
} from "../learningMaterialsTypes";
import TextEditingBlock from "./TextEditingBlock";

interface ParagraphTextProps {
    title?: string;
    subTitle?: string;
    
    editable: boolean;
    
    text: TParagraph['text'];
    setText: React.Dispatch<React.SetStateAction<TParagraph['text']>>;
}

const ParagraphText:FC<ParagraphTextProps> = props => {
    const {
        title,
        subTitle,
        
        editable,
        
        text,
        setText,
    } = props;
    
    const handleChangeParagraph = useCallback((key: string, action: React.SetStateAction<TTextMaterialStructure>) => {
        setText(text => ({
            ...text,
            [key]: typeof action === 'function' ? action(text[key]) : action,
        }));
    }, [setText]);
    
    let hasTitle = false;
    const getTitle = () => {
        if (hasTitle) {
            return undefined;
        } else {
            hasTitle = true;
            return title;
        }
    }
    
    let hasSubtitle = false;
    const getSubTitle = () => {
        if (hasSubtitle) {
            return undefined;
        } else {
            hasSubtitle = true;
            return subTitle;
        }
    }
    
    return (
        <React.Fragment>
            {Object.entries(text).map(([key, text]) => (
                <TextEditingBlock
                    key={key}
                    editable={editable}
                    title={getTitle()}
                    subTitle={getSubTitle()}
                    text={text}
                    // @ts-ignore
                    setText={text => handleChangeParagraph(key, text)}
                />
            ))}
        </React.Fragment>
    )
}

export default ParagraphText;