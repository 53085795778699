/** @format */

import queryString from 'query-string';
import {useMutation} from 'react-query';

import {downloadFromResponse} from "../../utils/commonFunctions";

import {apiV1Url} from '../../utils/commonConstants';

import {TLearningMaterials, TLearningMaterialsStructure} from './learningMaterialsTypes';

// Fetch lesson structure

export interface TLearningMaterialsRequestParams {
	token: string;
}

const fetchLessonStructureRequest = async ({token}) => {
	
	// await new Promise(res => setTimeout(res, 5000));
	//
	// return require('./structure.json');
	
	const response = await fetch(`${apiV1Url}/mutation/current/structure?${queryString.stringify({token})}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		}
	});
	
	if (response.ok) {
		return await response.json();
	} else {
		throw await response.json();
	}
}

export const useFetchLessonStructure = () => {
	const mutation = useMutation<{
		parameters: { topic: string },
		structure: TLearningMaterialsStructure,
	}, void, TLearningMaterialsRequestParams>({
		mutationFn: fetchLessonStructureRequest,
	});
	
	return {
		fetchLessonStructure: mutation.mutate,
		isLessonStructureLoading: mutation.isLoading,
	}
}

// Mutate structure

interface TMutateStructureParams {
	token: string;
	structure: TLearningMaterialsStructure
}

const mutateStructureRequest = async ({token, structure}: TMutateStructureParams) => {
	const response = await fetch(`${apiV1Url}/mutation/new?${queryString.stringify({token})}`, {
		method: 'POST',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			"Authorization": `Bearer ${token}`,
		},
		body: JSON.stringify({
			new_structure: cleanupLearningMaterialsForRequest(structure),
			token,
		}),
	});
	
	if (response.ok) {
		return void 0;
	} else {
		throw await response.json();
	}
}

export const useMutateStructure = () => {
	const mutation = useMutation<void, unknown, TMutateStructureParams>({
		mutationFn: mutateStructureRequest,
	});
	
	return {
		mutateStructure: mutation.mutate,
		isMutateStructureLoading: mutation.isLoading,
	}
}

const cleanupImageUrls = (materials: TLearningMaterials) => {
	const result = {...materials};
	
	if ('images' in materials) {
		result.images = Object.fromEntries(
			Object
				.entries(materials.images)
				.map(([key, image]) => [key, {...image, url: ''}])
		);
	}
	
	if ('paragraphs' in materials) {
		// @ts-ignore
		result.paragraphs = cleanupLearningMaterialsForRequest(result.paragraphs);
	}
	
	return result;
}

const cleanupLearningMaterialsForRequest = (learningMaterials: TLearningMaterialsStructure) => {
	return Object.fromEntries(Object.entries(learningMaterials).map(([key, material]) => [key, cleanupImageUrls(material)]));
}

// Download pdf

interface TDownloadPdfParams {
	token: string
}

const downloadPdfRequest = async ({token}: TDownloadPdfParams) => {
	const response = await fetch(`${apiV1Url}/mutation/current/pdf/download?${queryString.stringify({token})}`, {
		headers: {
			'Authorization': `Bearer ${token}`,
		}
	});
	
	if (response.ok) {
		return await response;
	} else {
		throw await response.json();
	}
}

export const useDownloadPdf = () => {
	const mutation = useMutation<Response, unknown, TDownloadPdfParams>({
		mutationFn: downloadPdfRequest,
		onSuccess: async (response) => {
			await downloadFromResponse(response, 'download.pdf');
		}
	});
	
	return {
		downloadPdf: mutation.mutate,
		isPdfDownloading: mutation.isLoading,
	}
}