/** @format */

import React, {FC} from 'react';
import {Link} from 'react-router-dom';

import classes from './button.module.scss';

interface LinkButtonProps {
	to: string,
	children?: React.ReactNode,
	variant?: 'primary' | 'outline'
}

const LinkButton: FC<LinkButtonProps> = props => {
	const {to, children, variant = 'primary'} = props;
	
	return (
		<Link
			to={to}
			className={classes.link_button}
			data-variant={variant}
		>
			<span className={classes.button_text}>
				{children}
			</span>
		</Link>
	)
}

export default LinkButton;