/** @format */

import React, {FC, useCallback} from 'react';
import {useNavigate, useParams} from 'react-router';
import {useTranslation} from 'react-i18next';

import Background from '../../components/Background';
import Header from '../../components/Header';
import Main from '../../components/Main';
import Button from '../../components/Button';

import {useDownloadPdf, usePdfStatus} from './pendingResultQueries';

import routes from '../../utils/routes';

import classes from './pendingResultPage.module.scss';
import { motion } from 'framer-motion';

const PendingResultPage: FC = () => {
	
	const sessionId = useParams<{ sessionId: string }>().sessionId as string;
	
	const {t} = useTranslation();
	
	const {pdfStatus} = usePdfStatus(sessionId);
	
	const {downloadPdf, isPdfDownloading} = useDownloadPdf();
	
	const navigate = useNavigate();
	
	const handleDownload = useCallback(() => {
		downloadPdf({sessionId}, {
			onSuccess: () => {
				navigate(routes.homePage())
			}
		})
	}, [downloadPdf, sessionId]);
	
	let content:React.ReactNode = null;
	
	if (isPdfDownloading) {
		content = (
			<>
				<h1 className={classes.title}>
					{t('pending_page.generating_title')}
				</h1>
				<p className={classes.description}>
					{t('pending_page.generating_description')}
				</p>
			</>
		)
	} else if (pdfStatus !== undefined && pdfStatus.status === 'finished') {
		content = (
			<>
				<h1 className={classes.title}>
					{t('pending_page.complete_title')}
				</h1>
				<div className={classes.actions}>
					<Button
						onClick={handleDownload}
						disabled={isPdfDownloading}
					>
						{t('pending_page.download_pdf')}
					</Button>
				</div>
			</>
		)
	} else {
		content = (
			<>
				<h1 className={classes.title}>
					{t('pending_page.pending_title')}
				</h1>
				<p className={classes.description}>
					{t('pending_page.pending_description')}
				</p>
			</>
		)
	}
	
	return (
		<Main className={classes.pending_page}>
			<Background/>
			<Header/>
			<motion.div
				className={classes.pending_container}
				initial={{opacity: 0, y: -20}}
				animate={{opacity: 1, y: 0}}
				exit={{opacity: 0, y: 20}}
			>
				{content}
			</motion.div>
		</Main>
	)
}

export default PendingResultPage;