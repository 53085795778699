/** @format */
import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from './translations/en.json'
import he from './translations/he.json'

const i18nInstance = i18next.createInstance();

i18nInstance
	.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		lng: localStorage.getItem('language') ?? 'en',
		supportedLngs: ['en', 'he'],
		fallbackLng: 'en',
		debug: false,
		interpolation: {
			escapeValue: false,
		},
		resources: {
			en: {translation: en},
			he: {translation: he},
		},
		
	})

i18nInstance.on('languageChanged', () => {
	localStorage.setItem('language', i18nInstance.language);
})

export default i18nInstance;