/** @format */

import React, {FC} from 'react';

import Header from '../Header';

import {clsx} from "../../utils/commonFunctions";

import classes from './background.module.scss';

interface BackgroundProps {
	children?: React.ReactNode,
	className?: string,
}

const Background: FC<BackgroundProps> = props => {
	const {children, className} = props;
	
	return (
		<div className={clsx(classes.background, className)}>
			{children}
		</div>
	)
}

export default Background;