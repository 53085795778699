/** @format */

import React, {FC, useCallback} from 'react';

import TextEditingBlock from './TextEditingBlock';

import {TLearningMaterials, TTextMaterialStructure, TWordMaterialStructure} from '../learningMaterialsTypes';


interface LearningMaterialsWordsProps {
	title?: string;
	subTitle?: string;
	
	editable: boolean;
	
	words: TLearningMaterials['words'];
	setWords: React.Dispatch<React.SetStateAction<TLearningMaterials['words']>>;
}

const LearningMaterialsWords: FC<LearningMaterialsWordsProps> = props => {
	const {
		title,
		subTitle,
		
		editable,
		
		words,
		setWords,
	} = props;
	
	const handleChangeParagraph = useCallback((key: string, action: React.SetStateAction<TWordMaterialStructure>) => {
		setWords(words => ({
			...words,
			[key]: typeof action === 'function' ? action(words[key]) : action,
		}));
	}, [setWords]);
	
	let hasTitle = false;
	const getTitle = () => {
		if (hasTitle) {
			return undefined;
		} else {
			hasTitle = true;
			return title;
		}
	}
	
	let hasSubtitle = false;
	const getSubTitle = () => {
		if (hasSubtitle) {
			return undefined;
		} else {
			hasSubtitle = true;
			return subTitle;
		}
	}
	
	return (
		<React.Fragment>
            {Object.entries(words).map(([key, text]) => (
                <TextEditingBlock
	                key={key}
	                editable={editable}
	                title={getTitle()}
	                subTitle={getSubTitle()}
	                text={text}
	                // @ts-ignore
	                setText={text => handleChangeParagraph(key, text)}
                />
            ))}
        </React.Fragment>
	)
}

export default LearningMaterialsWords;