/** @format */
import {useEffect, useState} from "react";

export const useIsMobileView = () => {
	const [isMobileView, setIsMobileView] = useState(
		window.matchMedia("(pointer: coarse)").matches &&
		window.matchMedia("(max-width: 500px)").matches
	);
	
	useEffect(() => {
		const pointerMql = window.matchMedia("(pointer: coarse)");
		const windowWidthMql = window.matchMedia("(max-width: 500px)");
		
		const handler = () => {
			setIsMobileView(
				pointerMql.matches &&
				windowWidthMql.matches
			);
		}
		
		pointerMql.addEventListener('change', handler);
		windowWidthMql.addEventListener('change', handler);
		
		return () => {
			pointerMql.removeEventListener('change', handler);
			windowWidthMql.removeEventListener('change', handler);
		}
	}, [])
	
	return isMobileView;
};