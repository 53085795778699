/** @format */

import React, {ChangeEvent, FC, useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import Button from '../../../components/Button';
import CheckboxBlock from '../../../components/CheckboxBlock';

import {TCreateStoryPageStepProps} from '../createStoryPageTypes';
import {ELessonActivity} from '../createStoryTypes';

import classes from './sessionsStepForm.module.scss'


interface SessionsStepFormProps extends TCreateStoryPageStepProps {
	activities: Record<ELessonActivity, boolean>;
	setActivities: (pages: Record<ELessonActivity, boolean>) => void;
}

const SessionsStepForm: FC<SessionsStepFormProps> = props => {
	const {
		activities,
		setActivities,
		
		onBack,
		onSubmit,
	} = props;
	
	const {t} = useTranslation();
	
	const handleSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		onSubmit();
	}, [onSubmit]);
	
	const handleTogglePage = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		setActivities({
			...activities,
			[event.currentTarget.value]: event.currentTarget.checked,
		});
	}, [setActivities, activities]);
	
	return (
		<form
			action="#"
			className={classes.session_form}
			onSubmit={handleSubmit}
		>
			<h3 className={classes.description}>
				{t('creation.session_step.title')}
			</h3>
			<div className={classes.session_grid}>
				<CheckboxBlock
					name="activity"
					value={ELessonActivity.learningNewVocabulary}
					className={classes.session_checkbox_block}
					checked={activities[ELessonActivity.learningNewVocabulary]}
					onChange={handleTogglePage}
				>
					<p className={classes.session_checkbox_block_text}>
						{t('creation.session_step.learning_of_new_vocabulary')}
					</p>
				</CheckboxBlock>
				<CheckboxBlock
					name="activity"
					value={ELessonActivity.pictures}
					className={classes.session_checkbox_block}
					checked={activities[ELessonActivity.pictures]}
					onChange={handleTogglePage}
				>
					<p className={classes.session_checkbox_block_text}>
						{t('creation.session_step.pictures_to_match_ideas_from_the_text')}
					</p>
				</CheckboxBlock>
				<CheckboxBlock
					name="activity"
					value={ELessonActivity.sequence}
					className={classes.session_checkbox_block}
					checked={activities[ELessonActivity.sequence]}
					onChange={handleTogglePage}
				>
					<p className={classes.session_checkbox_block_text}>
						{t('creation.session_step.story_board_to_practice_understanding_of_events_and_sequence')}
					</p>
				</CheckboxBlock>
				<CheckboxBlock
					name="activity"
					value={ELessonActivity.fillInBlank}
					className={classes.session_checkbox_block}
					checked={activities[ELessonActivity.fillInBlank]}
					onChange={handleTogglePage}
				>
					<p className={classes.session_checkbox_block_text}>
						{t('creation.session_step.a_close_to_exercise_fill_in_the_blank_skill_of_a_new_vocabulary')}
					</p>
				</CheckboxBlock>
				<CheckboxBlock
					name="activity"
					value={ELessonActivity.yesNoQuestions}
					className={classes.session_checkbox_block}
					checked={activities[ELessonActivity.yesNoQuestions]}
					onChange={handleTogglePage}
				>
					<p className={classes.session_checkbox_block_text}>
						{t('creation.session_step.quiz_with_yes_no')}
					</p>
				</CheckboxBlock>
				<CheckboxBlock
					name="activity"
					value={ELessonActivity.shortVideo}
					className={classes.session_checkbox_block}
					checked={activities[ELessonActivity.shortVideo]}
					// onChange={handleTogglePage}
					disabled
				>
					<p className={classes.session_checkbox_block_text}>
						{t('creation.session_step.short_videos_on_subject')}
					</p>
				</CheckboxBlock>
			</div>
			
			<div className={classes.actions}>
				<Button variant="outline" onClick={onBack}>{t('common.back')}</Button>
				<Button type="submit">{t('common.next')}</Button>
			</div>
		</form>
	)
}

export default SessionsStepForm;