/** @format */

import styles from './export.module.scss'

export const primary_900:string = styles.primary_900;
export const primary_800:string = styles.primary_800;
export const primary_700:string = styles.primary_700;
export const primary_600:string = styles.primary_600;
export const primary_500:string = styles.primary_500;
export const primary_400:string = styles.primary_400;
export const primary_300:string = styles.primary_300;
export const primary_200:string = styles.primary_200;
export const primary_100:string = styles.primary_100;
export const primary_50:string = styles.primary_50;
export const neutral_900:string = styles.neutral_900;
export const neutral_800:string = styles.neutral_800;
export const neutral_700:string = styles.neutral_700;
export const neutral_600:string = styles.neutral_600;
export const neutral_500:string = styles.neutral_500;
export const neutral_400:string = styles.neutral_400;
export const neutral_300:string = styles.neutral_300;
export const neutral_200:string = styles.neutral_200;
export const neutral_100:string = styles.neutral_100;
export const neutral_50:string = styles.neutral_50;
export const rose_500:string = styles.rose_500;
export const rose_400:string = styles.rose_400;
export const rose_50:string = styles.rose_50;