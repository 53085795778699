/** @format */

import React, {FC, memo, useCallback} from 'react';

import ImageEditingBlock from './ImageEditingBlock';

import {TImageMaterialStructure, TLearningMaterials} from '../learningMaterialsTypes';


interface LearningMaterialsImagesProps {
	title?: string;
	subTitle?: string;
	
	images: TLearningMaterials['images'];
	setImages: React.Dispatch<React.SetStateAction<TLearningMaterials['images']>>;
}

const LearningMaterialsImages: FC<LearningMaterialsImagesProps> = props => {
	const {images, setImages, title, subTitle} = props;
	
	const handleChangeImage = useCallback((key: string, action: React.SetStateAction<TImageMaterialStructure>) => {
		setImages(images => ({
			...images,
			[key]: typeof action === 'function' ? action(images[key]) : action,
		}));
	}, [setImages]);
	
	let hasTitle = false;
	const getTitle = () => {
		if (hasTitle) {
			return undefined;
		} else {
			hasTitle = true;
			return title;
		}
	}
	
	let hasSubtitle = false;
	const getSubTitle = () => {
		if (hasSubtitle) {
			return undefined;
		} else {
			hasSubtitle = true;
			return subTitle;
		}
	}
	
	return (
		<React.Fragment>
			{Object.entries(images).map(([key, image]) => (
				<ImageEditingBlock
					title={getTitle()}
					subTitle={getSubTitle()}
					key={key}
					image={image}
					setImage={image => handleChangeImage(key, image)}
				/>
			))}
		</React.Fragment>
	)
}

export default memo(LearningMaterialsImages);