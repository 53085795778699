/** @format */

import React, {FC} from 'react';

import checkedImg from '../CheckboxBlock/checkbox_checked.svg'

import classes from './stepper.module.scss';

interface StepperProps {
	max: number,
	value: number,
	onSet?: (value: number) => void
}

const Stepper: FC<StepperProps> = props => {
	const {max, value, onSet} = props;
	
	const contentNode: React.ReactNode[] = []
	
	let isPrev: boolean;
	let isNext: boolean;
	
	const hasCb = typeof onSet === 'function'
	
	for (let i = 1; i <= max; ++i) {
		isPrev = i < value;
		isNext = i > value;
		
		const handleClick = hasCb ? (
			() => onSet(i)
		) : undefined;
		
		contentNode.push(
			<button
				type="button"
				className={classes.stepper_dot}
				key={i}
				data-is-next={isNext}
				data-is-prev={isPrev}
				data-step={i}
				disabled={!isPrev || !hasCb}
				onClick={handleClick}
			/>
		)
		
		if (i < max) {
			contentNode.push(
				<div
					key={`${i}-divider`}
					className={classes.divider}
				/>
			)
		}
	}
	
	return (
		<div className={classes.stepper_container}>
			{contentNode}
		</div>
	)
}

export default Stepper;