/** @format */

export const clsx = (...classNames: unknown[]) => {
	let result:string[] = [];
	
	for (const className of classNames) {
		if (typeof className === 'string' && className !== '') {
			result.push(className);
		}
	}
	
	return result.join(' ');
};

const parseContentDisposition = (contentDisposition: string) => {
	const result:Record<string, string> = {};

	const parts = contentDisposition.split(';');

	for (const part of parts) {

		const execResult = /([\w-*]+)(?:="?([^/\\":*?<>|+]+)"?)?/g.exec(part.trim());
		if (execResult !== null) {
			const [,fieldName, fieldValue] = execResult;
			result[fieldName] = fieldValue ?? fieldName;
		}
	}

	return result;
}

export const getFileNameFromHeaders = (headers: Headers, defaultName: string) => {
	const contentDisposition = headers.get("content-disposition");

	if (contentDisposition === null) {
		return defaultName;
	}

	const parsedContentDisposition = parseContentDisposition(contentDisposition);

	if (parsedContentDisposition.filename === undefined) {
		return defaultName;
	}

	return decodeURI(parsedContentDisposition.filename);
}

export const downloadBlob = (blob: globalThis.Blob, fileName: string) => {
	const objectURL = URL.createObjectURL(blob);

	const a = document.createElement('a');
	a.download = fileName;
	a.href = objectURL;
	a.style.display = 'none';
	a.charset = 'UTF-8';
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
	URL.revokeObjectURL(objectURL);
}

export const downloadFromResponse = async (response: Response, defaultFileName: string) => {
	return downloadBlob(
		await response.blob(),
		getFileNameFromHeaders(response.headers, defaultFileName),
	);
}