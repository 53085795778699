/** @format */

import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {motion} from 'framer-motion';

import Background from '../../components/Background';
import LinkButton from '../../components/Button/LinkButton';
import Main from '../../components/Main';
import Header from '../../components/Header';

import routes from '../../utils/routes';

// import adornmentImg from './adornment_image.png';

import classes from './homePage.module.scss'

import playIconImg from './playIcon.svg';
import adornmentImg from './adornment_image_optimized.png';


const HomePage: FC = props => {
	const {} = props;
	
	const {t} = useTranslation();
	
	return (
		<Main>
			<Header/>
			<Background/>
			
			<motion.img
				className={classes.adornment_image}
				src={adornmentImg}
				alt="adornment image"
				
				initial={{opacity: 0}}
				animate={{opacity: 1}}
				transition={{duration: 0.4}}
				exit={{opacity: 0}}
			/>
			
			<div className={classes.content}>
				<motion.h1
					initial={{opacity: 0, y: -20}}
					animate={{opacity: 1, y: 0}}
					transition={{duration: 0.4}}
					className={classes.title}
					exit={{opacity: 0}}
				>
					{t('home.title')}
				</motion.h1>
				<motion.h3
					className={classes.description}
					initial={{opacity: 0, y: -20}}
					animate={{opacity: 1, y: 0, transition: {delay: 0.3}}}
					transition={{duration: 0.4}}
					exit={{opacity: 0}}
				>
					{t('home.description')}
				</motion.h3>
				<motion.div
					className={classes.actions}
					initial={{opacity: 0, y: -20}}
					animate={{opacity: 1, y: 0, transition: {delay: 0.6}}}
					transition={{duration: 0.4}}
					exit={{opacity: 0}}
				>
					<LinkButton to={routes.createStoryFlow()}>
						{t('home.create_button')}
					</LinkButton>
					{/*<a href="#" className={classes.how_it_works_link}>*/}
					{/*	<img src={playIconImg} alt="Play"/>*/}
					{/*	<span>*/}
					{/*		How It Works*/}
					{/*	</span>*/}
					{/*</a>*/}
				</motion.div>
			</div>
		</Main>
	)
}

export default HomePage;